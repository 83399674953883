import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, CardBody } from "reactstrap";

import Logo from "../common/Logo";

const Complete = () => {
	const [logo, setLogo] = useState(require("../assets/img/vda_logo.svg"));

	return (
		<>

			<Row noGutters>
				<Col xl={12}>
					<Card className="mb-3">
						<CardBody>
							<Logo location={"auth"} logo={logo} />
							<Row className="m-5">
								<Col xs="12" style={{ alignSelf: 'center', textAlign: 'center' }}>
									<h3>Proposal Submitted!</h3>
									<br /><br />
									<h5>Thank you for successfully submitting your proposal.  <br />If you have any further questions or comments, please contact us at the email below.</h5>
									<br />
									<h5><a href='mailto:metroinspections@vdassoc.com'>metroinspections@vdassoc.com</a></h5>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>

		</>
	);
};

export default Complete;
